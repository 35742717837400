import gql from 'graphql-tag'

export const QUERY_GET_PLATFORM_USERS = gql`
  query($id: ID!, $page: Int, $pageSize: Int, $search: String, $sort: [String], $sortDirection: [SortDirection]) {
    platformUsers(account_id: $id, page: $page, pageSize: $pageSize, search: $search, sort: $sort, sortDirection: $sortDirection) {
      count
      data {
        _id
        name
        erp_id
        document_no
        sync
        sync_at
        sync_last_retry
        sync_error
        confirmation_sent
        confirmation_sent_at
      }
    }
  }
`
export const MUTATION_UPDATE_USER_PLATFORM_SYNC = gql`
  mutation($id: String!, $sync: Boolean!, $doc: String!) {
    updateSync(account_id: $id, sync: $sync, document_no: $doc)
  }
`
export const MUTATION_MASSIVE_SYNC_PLATFORM_USERS = gql`
  mutation($id: String!) {
    updateSyncMassive(account_id: $id)
  }
`
export const QUERY_GET_USER_FROM_BLOCKLIST = gql`
  query($account_id: ID!, $document_no: String) {
    checkUserBlocklist(account_id: $account_id, document_no: $document_no)
  }
`
export const MUTATION_BLOCKLIST_USER = gql`
  mutation($account_id: ID!, $document_no: String!, $flag: String!) {
    blocklistUser(account_id: $account_id, document_no: $document_no, flag: $flag)
  }
`
export const MUTATION_REMOVE_USER = gql`
  mutation($account_id: ID!, $document_no: String!) {
    adminRemoveUser(account_id: $account_id, document_no: $document_no)
  }
`
export const MUTATION_BLOCKLIST_USERS_FROM_CSV = gql`
  mutation($account_id: ID!, $document_no: [String], $flag: String!) {
    blocklistUsersFromCsv(account_id: $account_id, document_no: $document_no, flag: $flag)
  }
`
