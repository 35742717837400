import gql from 'graphql-tag'

export const MUTATION_EDIT_RETRY_SKAAR = gql`
  mutation($id: ID!, $value: Boolean!) {
    adminUpdateRetryErpSync(id: $id, value: $value)
  }
`
export const MUTATION_UPDATE_SKAAR_PAREMETERS = gql`
  mutation($id: ID!, $parameter_name: String!, $parameter_value: String!) {
    adminUpdateParametersErpSync(id: $id, parameter_name: $parameter_name, parameter_value: $parameter_value)
  }
`
export const MUTATION_REMOVE_SKAAR_PARAM = gql`
  mutation($id: ID!, $key: String!) {
    adminRemoveErpSyncParam(id: $id, key: $key)
  }
`
export const MUTATION_EDIT_SKAAR_SERVER = gql`
  mutation($id: ID!, $index: Int!, $host: String!, $port: String!, $user: String!, $password: String!, $database_name: String) {
    adminEditSkaarServer(id: $id, index: $index, host: $host, port: $port, user: $user, password: $password, database_name: $database_name)
  }
`
export const MUTATION_ADD_SKAAR_SERVER = gql`
  mutation($id: ID!, $host: String!, $port: String!, $user: String!, $password: String!, $database_name: String!) {
    adminAddSkaarServer(id: $id, host: $host, port: $port, user: $user, password: $password, database_name: $database_name)
  }
`
export const MUTATION_REMOVE_SKAAR_SERVER = gql`
  mutation($id: ID!, $index: Int!) {
    adminRemoveSkaarServer(id: $id, index: $index)
  }
`
