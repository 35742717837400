import gql from 'graphql-tag'

export const QUERY_GET_ERPS = gql`
  {
    adminERPs {
      _id
      name
      acronym
      erp_index
      clients_index
    }
  }
`
export const QUERY_GET_ACCOUNT = gql`
  query adminAccount($_id: ID!) {
    adminAccount(_id: $_id) {
      _id
      name
      acronym
      db_name
      active
      id_external
      cnpj
      loyalty_program_start_date
      stage {
        import_key
        full_etl
        regenerate_loyalty
        truncate_loyalty
      }
      status_account {
        deploying
        done
        impossible_support
        test
        churn
        temporary_churn
        adjusting
        deprecated
        waiting_release
      }
      app_configuration
      email_credentials {
        login
        password
      }
      channels {
        app {
          points {
            active
          }
          active
        }
        hotsite {
          active
        }
        email {
          domain
          sender_name
        }
        cashback {
          active
        }
        pdv {
          disable_vouchers
        }
        database {
          host
        }
      }
      modules
      erp {
        name
        acronym
        import_configuration {
          integrator
          driver
          sqls
          views
          commands
          output
        }
      }
      locks {
        integrator
        postgres
        monet
        clickhouse
        mongo
      }
      sms_credentials {
        login
        password
        provider
      }
      password_recovery_sms_credentials {
        login
        password
        provider
      }
      whatsapp_credentials {
        providers {
          fcm {
            login
            password
            token
            active
          }
        }
      }
      prices {
        credits {
          sms
          password_recovery_sms
          email
        }
      }
      geo_configuration {
        key
        solver
        limit
      }
      import_configuration {
        released
        active
        realtime
        first_value
        use_integrators_per_server
        strict_erp_names
        first_load {
          parallelism
          start_date
          end_date
          scale
        }
        servers {
          integrator
          connection {
            host
            port
            password
            user
            database
            charset
          }
          configuration {
            filters
            sqls
            views
            variables
          }
        }
      }
      status_reporter {
        max_date
        status
      }
      dag {
        parameters
        variables
        tag
        schedule_interval
        running
        last_start_date
        last_completion_date
      }
      processing_configuration {
        unified
        bloated
        version
        encoding
        date_formats
        hour_formats
      }
      erp_sync
      erp_integrators
      integration_type
      tribanco
      summaries {
        force_all_campaigns_update
      }
      flags {
        products {
          use_measure_units_on_sales
          wrong_bases
        }
        catalog_global {
          use
          use_products_images
          use_products_information
          use_categories
        }
        sales {
          import_sales_with_specific_products
          ignore_imported_sales_on_pos
          ignore_images_on_sales
          use_realtime_cashback
        }
        app_sales {
          use_product_scores_sorting
          use_user_product_scores_sorting
        }
        ecommerce {
          active
        }
        cbm {
          active
        }
        industry {
          has_mercapromo
          only_mercapromo
        }
        app_custom_printscreen
      }
      client_id
      franchise_id
      is_franchise
    }
  }
`
export const QUERY_GET_ACCOUNTS = gql`
  query($active: Boolean, $franchise: Boolean, $status: String, $client_id: String) {
    adminAccounts(active: $active, franchise: $franchise, status: $status, client_id: $client_id) {
      _id
      name
      acronym
      db_name
      active
      id_external
      status_account {
        deploying
        done
        impossible_support
        test
        churn
        temporary_churn
        adjusting
        deprecated
      }
      erp {
        name
        acronym
      }
      status_reporter {
        last_dag_completion
        max_date
        status
      }
      dag {
        running
        schedule_interval
        tag
      }
      channels {
        app {
          active
        }
        hotsite {
          active
        }
        pdv {
          disable_vouchers
        }
      }
    }
  }
`
export const QUERY_GET_ACCOUNTS_PAGINATED = gql`
  query getAccounts($input: GetAccountsInput!, $pagination: PaginationParams!) {
    getAccounts(input: $input, pagination: $pagination) {
      data {
        _id
        name
        acronym
        db_name
        active
        id_external
        integration_type
        status_account {
          deploying
          done
          impossible_support
          test
          churn
          temporary_churn
          adjusting
          deprecated
          waiting_release
        }
        erp {
          name
          acronym
        }
        status_reporter {
          max_date
          status
          drift
          last_dag_completion
        }
        dag {
          running
          schedule_interval
          tag
        }
        channels {
          app {
            active
          }
          hotsite {
            active
          }
          pdv {
            disable_vouchers
          }
        }
        flags {
          ecommerce {
            active
          }
          cbm {
            active
          }
        }
        id_client
      }
      count
    }
  }
`

export const QUERY_GET_DATA_ACCOUNT = gql`
  query adminAccountsFiltered(
    $active: Boolean
    $franchise: Boolean
    $status: String
    $integration: String
    $dagStatus: String
    $page: Int
    $pageSize: Int
    $search: String
    $sort: [String]
    $sortDirection: [SortDirection]
    $onlyEcommerce: Boolean
    $onlyCbm: Boolean
  ) {
    adminAccountsFiltered(
      active: $active
      franchise: $franchise
      status: $status
      integration: $integration
      dagStatus: $dagStatus
      page: $page
      pageSize: $pageSize
      search: $search
      sort: $sort
      sortDirection: $sortDirection
      onlyEcommerce: $onlyEcommerce
      onlyCbm: $onlyCbm
    ) {
      data {
        _id
        name
        acronym
        db_name
        active
        id_external
        integration_type
        status_account {
          deploying
          done
          impossible_support
          test
          churn
          temporary_churn
          adjusting
          deprecated
        }
        erp {
          name
          acronym
        }
        status_reporter {
          max_date
          status
          drift
          last_dag_completion
        }
        dag {
          running
          schedule_interval
          tag
        }
        channels {
          app {
            active
          }
          hotsite {
            active
          }
          pdv {
            disable_vouchers
          }
        }
        flags {
          ecommerce {
            active
          }
          cbm {
            active
          }
        }
      }
      count
    }
  }
`

export const MUTATION_CREATE_ACCOUNT = gql`
  mutation(
    $name: String!
    $acronym: String!
    $integration_mode: AdminAccountIntegratonMode!
    $erp: String!
    $franchise: ID
    $loyalty_program_start_date: String!
    $status: String!
    $ecommerce_active: Boolean
    $cbm_active: Boolean
    $has_mercapromo: Boolean
    $only_mercapromo: Boolean
    $client_id: String
    $connection: AdminAccountConnectionType
  ) {
    adminCreateAccount(
      name: $name
      acronym: $acronym
      integration_mode: $integration_mode
      erp: $erp
      franchise: $franchise
      loyalty_program_start_date: $loyalty_program_start_date
      status: $status
      ecommerce_active: $ecommerce_active
      cbm_active: $cbm_active
      has_mercapromo: $has_mercapromo
      only_mercapromo: $only_mercapromo
      client_id: $client_id
      connection: $connection
    ) {
      _id
    }
  }
`
export const MUTATION_EDIT_ACCOUNT = gql`
  mutation(
    $_id: ID!
    $active: Boolean
    $name: String
    $id_external: Int
    $loyalty_program_start_date: String
    $cnpj: [String]
    $status: AdminAccountStatusInput
    $client_id: String
  ) {
    adminEditAccount(
      _id: $_id
      active: $active
      name: $name
      id_external: $id_external
      loyalty_program_start_date: $loyalty_program_start_date
      cnpj: $cnpj
      status: $status
      client_id: $client_id
    )
  }
`
export const MUTATION_UPDATE_MODULES = gql`
  mutation($id: ID!, $modules: JSONObject!) {
    updateAccountModules(id: $id, modules: $modules)
  }
`
export const MUTATION_UPDATE_FLAGS = gql`
  mutation(
    $id: ID!
    $tribanco: Boolean
    $use_measure_units_on_sales: Boolean
    $use_catalog: Boolean
    $use_products_images: Boolean
    $use_products_information: Boolean
    $use_categories: Boolean
    $import_sales_with_specific_products: Boolean
    $ignore_imported_sales_on_pos: Boolean
    $ignore_images_on_sales: Boolean
    $use_realtime_cashback: Boolean
    $use_product_scores_sorting: Boolean
    $use_user_product_scores_sorting: Boolean
    $force_all_campaigns_update: Boolean
    $ecommerce_active: Boolean
    $app_custom_printscreen: Boolean
    $cbm_active: Boolean
    $has_mercapromo: Boolean
    $only_mercapromo: Boolean
    $products_wrong_bases: Boolean
  ) {
    updateAccountFlags(
      id: $id
      tribanco: $tribanco
      use_measure_units_on_sales: $use_measure_units_on_sales
      use_catalog: $use_catalog
      use_products_images: $use_products_images
      use_products_information: $use_products_information
      use_categories: $use_categories
      import_sales_with_specific_products: $import_sales_with_specific_products
      ignore_imported_sales_on_pos: $ignore_imported_sales_on_pos
      ignore_images_on_sales: $ignore_images_on_sales
      use_realtime_cashback: $use_realtime_cashback
      use_product_scores_sorting: $use_product_scores_sorting
      use_user_product_scores_sorting: $use_user_product_scores_sorting
      force_all_campaigns_update: $force_all_campaigns_update
      ecommerce_active: $ecommerce_active
      app_custom_printscreen: $app_custom_printscreen
      cbm_active: $cbm_active
      has_mercapromo: $has_mercapromo
      only_mercapromo: $only_mercapromo
      products_wrong_bases: $products_wrong_bases
    )
  }
`
export const MUTATION_UPDATE_PDV_VOUCHERS = gql`
  mutation($account_id: ID!, $value: Boolean!) {
    disableVouchers(account_id: $account_id, value: $value)
  }
`
