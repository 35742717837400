import gql from 'graphql-tag'

export const MUTATION_ADD_FORMAT = gql`
  mutation($id: ID!, $flag: String!, $key: String!, $value: String!) {
    addFormat(id: $id, flag: $flag, key: $key, value: $value)
  }
`
export const MUTATION_REMOVE_FORMAT = gql`
  mutation($id: ID!, $key: String!, $table: String!) {
    removeFormat(id: $id, key: $key, table: $table)
  }
`
export const MUTATION_UPDATE_PROCESSING_CONFIGURATION = gql`
  mutation($id: ID!, $version: String, $encoding: String, $unified: Boolean, $bloated: Boolean) {
    updateProcessConfig(id: $id, version: $version, encoding: $encoding, unified: $unified, bloated: $bloated)
  }
`
export const MUTATION_UPDATE_FORMATS = gql`
  mutation($id: ID!, $formats: JSONObject, $key: String!) {
    updateProcessingConfigFormats(id: $id, formats: $formats, key: $key)
  }
`
