import gql from 'graphql-tag'

export const MUTATE_EMAIL_CONFIG_PARAMS = gql`
  mutation($id: ID!, $login: String, $password: String, $domain: String, $sender_name: String, $price: Float) {
    adminSaveCloudflareZoneParams(params: { id: $id, login: $login, password: $password, domain: $domain, sender_name: $sender_name, price: $price })
  }
`
export const MUTATE_ADD_CLOUDFLARE_ZONE = gql`
  mutation($id: ID!) {
    adminAddCloudflareZone(id: $id)
  }
`
export const MUTATE_ADD_DNS_RECORD = gql`
  mutation($id: ID!, $tsv: String!) {
    adminAddDnsRecord(id: $id, tsv: $tsv)
  }
`
export const QUERY_GET_CLOUDFLARE_ZONES = gql`
  query($id: ID!) {
    adminGetZoneList(id: $id) {
      name_servers
      original_name_servers
    }
  }
`
