import gql from 'graphql-tag'

export const MUTATION_ACTIVATE_ACCOUNT_CASHBACK = gql`
  mutation($id: ID!, $active: Boolean!) {
    adminActivateAccountCashback(id: $id, active: $active)
  }
`
export const MUTATION_ACTIVATE_APP_CASHBACK = gql`
  mutation adminActivateAppCashback($id: ID!, $active: Boolean!, $start_date: String!, $end_date: String!) {
    adminActivateAppCashback(id: $id, active: $active, start_date: $start_date, end_date: $end_date)
  }
`
export const QUERY_GET_ACCOUNT_CONFIGURATION = gql`
  query($id: ID!) {
    adminGetAccountConfiguration(id: $id)
  }
`
export const QUERY_GET_HOME_BUTTON = gql`
  query($id: ID!) {
    adminGetHomeButtons(id: $id) {
      _id
      link
      flagText
      icon
      position
      title
      type
      icon_type
      units
    }
  }
`
export const MUTATION_EDIT_CASHBACK_HOME_BUTTONS = gql`
  mutation($id: ID!, $flagText: String!, $position: Int!, $title: String!, $units: [String]) {
    adminEditCashbackHomeButton(id: $id, flagText: $flagText, position: $position, title: $title, units: $units)
  }
`
