import gql from 'graphql-tag'

export const MUTATION_EDIT_IMPORT_FLAGS = gql`
  mutation editImportFlags($id: ID!, $active: Boolean, $released: Boolean, $realtime: Boolean) {
    editImportFlags(id: $id, active: $active, released: $released, realtime: $realtime)
  }
`
export const MUTATION_EDIT_FIRST_LOAD = gql`
  mutation editFirstLoad($id: ID!, $selectedScale: String!, $scaleAmount: Int!, $startDate: String!, $endDate: String!, $parallelism: Int!) {
    editFirstLoad(
      id: $id
      params: { selectedScale: $selectedScale, scaleAmount: $scaleAmount, startDate: $startDate, endDate: $endDate, parallelism: $parallelism }
    )
  }
`
export const MUTATION_ADD_CONNECTION_SERVER = gql`
  mutation addServer(
    $id: ID!
    $pos: Boolean
    $integrator: String
    $host: String
    $port: String
    $password: String
    $user: String
    $database: String
    $charset: String
  ) {
    addServer(
      id: $id
      server: { pos: $pos, integrator: $integrator, host: $host, port: $port, password: $password, user: $user, database: $database, charset: $charset }
    )
  }
`
export const MUTATION_EDIT_CONNECTION_SERVER = gql`
  mutation editConnection($id: ID!, $server_index: Int!, $host: String, $port: String, $password: String, $user: String, $database: String, $charset: String) {
    editConnection(
      id: $id
      server_index: $server_index
      connection: { host: $host, port: $port, password: $password, user: $user, database: $database, charset: $charset }
    )
  }
`
export const MUTATION_REMOVE_SERVER_CONNECTION = gql`
  mutation removeServerConnection($id: ID!, $index: Int!) {
    removeServerConnection(id: $id, index: $index)
  }
`
export const MUTATION_REMOVE_QUERY = gql`
  mutation removeQuery($id: ID!, $index: Int!, $key: String!) {
    removeQuery(id: $id, index: $index, key: $key)
  }
`
export const MUTATION_REMOVE_VIEW = gql`
  mutation removeView($id: ID!, $index: Int!, $key: String!) {
    removeView(id: $id, index: $index, key: $key)
  }
`
export const MUTATION_REMOVE_VARIABLE = gql`
  mutation removeVariable($id: ID!, $index: Int!, $key: String!) {
    removeVariable(id: $id, index: $index, key: $key)
  }
`
export const MUTATION_REMOVE_FILTER = gql`
  mutation removeFilter($id: ID!, $index: Int!, $key: String!) {
    removeFilter(id: $id, index: $index, key: $key)
  }
`
export const MUTATE_FIX_BILLING = gql`
  mutation adminFixBilling($id: ID!, $integration: String!) {
    adminFixBilling(id: $id, integration: $integration)
  }
`
export const MUTATE_ADD_UNIT = gql`
  mutation adminAddFilter($id: ID!, $server_index: Int!, $unit: String!) {
    adminAddFilter(id: $id, server_index: $server_index, unit: $unit)
  }
`
export const MUTATE_ADD_SQL = gql`
  mutation adminAddSql($id: ID!, $server_index: Int!, $sql: SqlInput!, $key: String!) {
    adminAddSql(id: $id, server_index: $server_index, sql: $sql, key: $key)
  }
`
export const MUTATE_ADD_VIEW = gql`
  mutation adminAddView($id: ID!, $server_index: Int!, $sql: SqlInput!, $key: String!) {
    adminAddView(id: $id, server_index: $server_index, sql: $sql, key: $key)
  }
`
export const MUTATE_ADD_VARIABLE = gql`
  mutation adminAddVariable($id: ID!, $server_index: Int!, $value: String!, $key: String!) {
    adminAddVariable(id: $id, server_index: $server_index, value: $value, key: $key)
  }
`
export const MUTATE_EDIT_SQL = gql`
  mutation adminEditSql(
    $id: ID!
    $serverIndex: Int!
    $key: String!
    $inherit: Boolean!
    $first_value: Boolean
    $atemporal: Boolean!
    $query: String
    $date_field: String
    $date_filter_format: String
    $local_date_parser: String
    $realtime: String
  ) {
    adminEditSql(
      id: $id
      serverIndex: $serverIndex
      key: $key
      inherit: $inherit
      first_value: $first_value
      atemporal: $atemporal
      query: $query
      date_field: $date_field
      date_filter_format: $date_filter_format
      local_date_parser: $local_date_parser
      realtime: $realtime
    )
  }
`
export const MUTATE_EDIT_VIEW = gql`
  mutation adminEditView(
    $id: ID!
    $serverIndex: Int!
    $key: String!
    $inherit: Boolean!
    $first_value: Boolean
    $atemporal: Boolean!
    $query: String
    $date_field: String
    $date_filter_format: String
    $local_date_parser: String
    $realtime: String
  ) {
    adminEditView(
      id: $id
      serverIndex: $serverIndex
      key: $key
      inherit: $inherit
      first_value: $first_value
      atemporal: $atemporal
      query: $query
      date_field: $date_field
      date_filter_format: $date_filter_format
      local_date_parser: $local_date_parser
      realtime: $realtime
    )
  }
`
export const MUTATE_EDIT_MONGO_QUERY = gql`
  mutation editMongoQuery($id: ID!, $key: String!, $index: Int!, $query: JSONObject) {
    editMongoQuery(id: $id, key: $key, index: $index, query: $query)
  }
`
export const MUTATE_EDIT_FIELDS_OUTPUT_ORDER = gql`
  mutation updateMongoQueryFieldsOutputOrder($id: ID!, $index: Int!, $key: String!, $property: String!, $flag: String!) {
    updateMongoQueryFieldsOutputOrder(id: $id, index: $index, key: $key, property: $property, flag: $flag)
  }
`
export const MUTATE_ADD_NEW_MONGO_QUERY = gql`
  mutation addMongoQuery($id: ID!, $index: Int!, $key: String!, $query: JSONObject!) {
    addMongoQuery(id: $id, index: $index, key: $key, query: $query)
  }
`
