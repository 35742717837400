import gql from 'graphql-tag'

export const QUERY_GET_REPORTS = gql`
  query($id: ID!) {
    geReports(id: $id) {
      _id
      db_name
      doc_path
      created_at
    }
  }
`
