import gql from 'graphql-tag'

export const MUTATION_CREATE_HOME_BUTTON = gql`
  mutation($id: ID!, $title: String!, $subtitle: String!, $link: String!, $icon: String!, $flagText: String) {
    adminCreateHomeButton(id: $id, title: $title, subtitle: $subtitle, link: $link, icon: $icon, flagText: $flagText)
  }
`

export const MUTATION_INSERT_BUTTON = gql`
  mutation($input: InsertButtonInput!) {
    insertButton(input: $input) {
      message
      status
    }
  }
`
export const QUERY_AVAILABLE_ICONS = gql`
  query {
    adminGetAvailableIcons {
      icons
    }
  }
`
export const MUTATION_UPDATE_BUTTON = gql`
  mutation($input: InsertButtonInput!) {
    updateButton(input: $input) {
      message
      status
    }
  }
`

export const MUTATION_DELETE_BUTTON = gql`
  mutation($input: DeleteButtonInput!) {
    deleteButton(input: $input) {
      message
      status
    }
  }
`
export const MUTATION_UPDATE_BUTTON_POSITION = gql`
  mutation($app_type: AppType!, $input: UpdateButtonPositionInput!) {
    updateButtonPosition(app_type: $app_type, input: $input) {
      status
      message
    }
  }
`
export const QUERY_GET_BUTTONS = gql`
  query($account_id: String!) {
    buttons(account_id: $account_id) {
      _id
      club
      created_at
      ecommerce
      icon
      icon_type
      link
      position
      subtitle
      title
      type
      units
      flagText
      shortcutOrder
      triggerOrder
    }
  }
`
export const QUERY_GET_UNITS = gql`
  query($account_id: String!) {
    units(account_id: $account_id) {
      _id
      id_erp
      name
    }
  }
`
export const MUTATION_UPDATE_HOME_BUTTON = gql`
  mutation($id: ID!, $title: String!, $subtitle: String!, $link: String!, $icon: String!, $flagText: String, $units: [String]) {
    adminUpdateHomeButton(id: $id, title: $title, subtitle: $subtitle, link: $link, icon: $icon, flagText: $flagText, units: $units)
  }
`

export const QUERY_GET_HOME_BUTTONS = gql`
  query($id: ID!) {
    adminGetDefaultHomeButtons(id: $id) {
      link
      subtitle
      icon
      position
      title
      type
      flagText
      units
    }
  }
`

export const QUERY_GET_AVAILABLE_ICONS = gql`
  query($id: ID!) {
    adminGetAvailableIcons(id: $id) {
      icons
    }
  }
`
