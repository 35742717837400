import gql from 'graphql-tag'

export const QUERY_GET_ACCOUNTS_BY_IDS = gql`
  query($ids: [String]) {
    accountsByIds(ids: $ids) {
      _id
      name
      db_name
    }
  }
`

export const QUERY_GET_FLAGS_BY_ACCOUNTID = gql`
  query($accountId: String!) {
    retailerFlags(accountId: $accountId) {
      app {
        assets {
          masterIconNavbar
        }
        colors {
          composition {
            components {
              name
              value
            }
            general
          }
          iconBackgroundColor
          primary
          primaryDark
          primaryLight
          primaryText
          primaryTextDark
          primaryTextLight
          secondary
          secondaryDark
          secondaryLight
          secondaryText
          secondaryTextDark
          secondaryTextLight
          splash
        }
      }
      default
      id
      logoSelectorRetailerFlag
      name
    }
  }
`

export const QUERY_GET_UNITS_BY_ACCOUNTS = gql`
  query($accounts: String!) {
    getUnitsByAccounts(accounts: $accounts) {
      _id
      active
      account
      ecommerce_id
      slug
      corporate_name
      id_erp
      name
      cnpj
      address
      contact
      mobile
      pdv_token
      tags {
        name
        color
      }
      location {
        type
        coordinates
      }
      address_components {
        postal_code
        street
        neighborhood
        number
        complement
        city
        city_name
        federative_unit
      }
      cbm
      mercashop
      mercapromo
      promotrade
      segments
      ecommerce {
        active
        products_file {
          filename
          nof_products
          updated_at
          url
        }
      }
      total_pdv_amount
      total_pdv_contracted
      office_hours {
        monday {
          start
          finish
        }
        tuesday {
          start
          finish
        }
        wednesday {
          start
          finish
        }
        thursday {
          start
          finish
        }
        friday {
          start
          finish
        }
        saturday {
          start
          finish
        }
        sunday {
          start
          finish
        }
        holidays {
          start
          finish
        }
      }
    }
  }
`

export const QUERY_GET_UNITS_BY_FRANCHISE = gql`
  query($franchiseId: String!) {
    getUnitsByFranchise(franchiseId: $franchiseId) {
      _id
      active
      account
      slug
      corporate_name
      id_erp
      name
      cnpj
      address
      contact
      mobile
      pdv_token
      tags {
        name
        color
      }
      location {
        type
        coordinates
      }
      address_components {
        postal_code
        street
        neighborhood
        number
        complement
        city
        city_name
        federative_unit
      }
      cbm
      mercashop
      mercapromo
      promotrade
      segments
      ecommerce {
        active
        products_file {
          filename
          nof_products
          updated_at
          url
        }
      }
      total_pdv_amount
      total_pdv_contracted
      office_hours {
        monday {
          start
          finish
        }
        tuesday {
          start
          finish
        }
        wednesday {
          start
          finish
        }
        thursday {
          start
          finish
        }
        friday {
          start
          finish
        }
        saturday {
          start
          finish
        }
        sunday {
          start
          finish
        }
        holidays {
          start
          finish
        }
      }
    }
  }
`

export const QUERY_UNITS = gql`
  query units {
    units {
      _id
      ecommerce_id
      active
      slug
      corporate_name
      municipal_insc
      statual_insc
      id_erp
      name
      cnpj
      address
      contact
      mobile
      pdv_token
      token_ecommerce
      store_main
      retailer_flag_id
      client_unit_id
      tags {
        name
        color
      }
      location {
        type
        coordinates
      }
      address_components {
        postal_code
        street
        neighborhood
        number
        complement
        city
        city_name
        federative_unit
      }
      cbm
      mercashop
      mercapromo
      promotrade
      segments
      ecommerce {
        active
        products_file {
          filename
          nof_products
          updated_at
          url
        }
      }
      total_pdv_amount
      total_pdv_contracted
      office_hours {
        monday {
          start
          finish
        }
        tuesday {
          start
          finish
        }
        wednesday {
          start
          finish
        }
        thursday {
          start
          finish
        }
        friday {
          start
          finish
        }
        saturday {
          start
          finish
        }
        sunday {
          start
          finish
        }
        holidays {
          start
          finish
        }
      }
      created_at
      updated_at
    }
  }
`

export const QUERY_CLIENT_UNITS = gql`
  query clientUnits($client_units: ClientUnitsInput!) {
    clientUnits(client_units: $client_units) {
      _id
      name
      cnpj
      status
      corporate_name
      statual_insc
      municipal_insc
      segments
      total_pdv_amount
      total_pdv_contracted
      store_main
      location {
        type
        coordinates
      }
      address
      address_components {
        postal_code
        street
        neighborhood
        number
        complement
        city
        city_name
        federative_unit
      }
      cbm
      mercapromo
      mercashop
      promotrade
    }
  }
`

export const QUERY_FLAGS = gql`
  query RetailerFlags {
    retailerFlags {
      _id
      name
    }
  }
`
export const MUTATION_ADD_UNIT = gql`
  mutation RegisterUnit(
    $client_unit_id: String!
    $address: String!
    $cbm: Boolean
    $mercapromo: Boolean
    $cnpj: String!
    $contact: String
    $id_erp: String!
    $mobile: Boolean!
    $name: String!
    $pdv_token: String
    $segments: [String!]
    $total_pdv_amount: Int
    $total_pdv_contracted: Int
    $office_hours: OfficeHoursInput!
    $location: LocationInput!
    $address_components: AddressInput!
    $ecommerce: UnitEcommerceInput
    $ecommerce_id: String
    $corporate_name: String
    $slug: String
    $statual_insc: String
    $municipal_insc: String
    $token_ecommerce: String
    $store_main: Boolean
    $ecommerce_v2_active: Boolean
    $retailer_flag_id: String
  ) {
    registerUnit(
      unit: {
        client_unit_id: $client_unit_id
        address: $address
        cbm: $cbm
        mercapromo: $mercapromo
        cnpj: $cnpj
        contact: $contact
        id_erp: $id_erp
        mobile: $mobile
        name: $name
        pdv_token: $pdv_token
        segments: $segments
        total_pdv_amount: $total_pdv_amount
        total_pdv_contracted: $total_pdv_contracted
        office_hours: $office_hours
        location: $location
        address_components: $address_components
        ecommerce: $ecommerce
        ecommerce_id: $ecommerce_id
        corporate_name: $corporate_name
        slug: $slug
        statual_insc: $statual_insc
        municipal_insc: $municipal_insc
        token_ecommerce: $token_ecommerce
        store_main: $store_main
        ecommerce_v2_active: $ecommerce_v2_active
        retailer_flag_id: $retailer_flag_id
      }
    ) {
      success
      message
    }
  }
`

export const MUTATION_REMOVE_UNIT = gql`
  mutation RemoveUnit($unit_id: String!) {
    deleteUnit(unit_id: $unit_id) {
      success
      message
    }
  }
`

export const MUTATION_UNIT = gql`
  mutation UpdateUnit(
    $unit_id: String!
    $address: String
    $address_components: AddressInput
    $cbm: Boolean
    $mercashop: Boolean
    $mercapromo: Boolean
    $promotrade: Boolean
    $cnpj: String
    $contact: String
    $ecommerce: UnitEcommerceInput
    $location: LocationInput
    $mobile: Boolean
    $name: String
    $office_hours: OfficeHoursInput
    $pdv_token: String
    $segments: [String!]
    $tags: [TagInput!]
    $total_pdv_amount: Int
    $total_pdv_contracted: Int
    $ecommerce_id: String
    $corporate_name: String
    $slug: String
    $statual_insc: String
    $municipal_insc: String
    $token_ecommerce: String
    $store_main: Boolean
    $active: Boolean
    $ecommerce_v2_active: Boolean
    $retailer_flag_id: String
  ) {
    updateUnit(
      unit_id: $unit_id
      update: {
        address: $address
        address_components: $address_components
        cbm: $cbm
        mercashop: $mercashop
        mercapromo: $mercapromo
        promotrade: $promotrade
        cnpj: $cnpj
        contact: $contact
        ecommerce: $ecommerce
        location: $location
        mobile: $mobile
        name: $name
        office_hours: $office_hours
        pdv_token: $pdv_token
        segments: $segments
        tags: $tags
        total_pdv_amount: $total_pdv_amount
        total_pdv_contracted: $total_pdv_contracted
        ecommerce_id: $ecommerce_id
        corporate_name: $corporate_name
        slug: $slug
        statual_insc: $statual_insc
        municipal_insc: $municipal_insc
        token_ecommerce: $token_ecommerce
        store_main: $store_main
        active: $active
        ecommerce_v2_active: $ecommerce_v2_active
        retailer_flag_id: $retailer_flag_id
      }
    ) {
      success
      message
    }
  }
`
