import gql from 'graphql-tag'

export const QUERY_GET_FLAGS = gql`
  query getGeneralFlags($account_id: String!) {
    getGeneralFlags(account_id: $account_id) {
      flags {
        app_custom_printscreen
        cbm {
          active
        }
        products {
          use_measure_units_on_sales
        }
        catalog_global {
          use
          use_products_images
          use_products_information
          use_categories
        }
        sales {
          ignore_images_on_sales
          ignore_imported_sales_on_pos
          import_sales_with_specific_products
          use_realtime_cashback
        }
        app_sales {
          use_product_scores_sorting
          use_user_product_scores_sorting
        }
      }
      summaries {
        force_all_campaigns_update
      }
    }
  }
`

export const MUTATION_UPDATE_GENERAL_FLAGS = gql`
  mutation updateGeneralFlags($account_id: String!, $flags: GeneralFlags!) {
    updateGeneralFlags(account_id: $account_id, flags: $flags) {
      message
      success
    }
  }
`
