import gql from 'graphql-tag'

export const QUERY_CAMPAIGNS = gql`
  query($account_id: ID!, $params: JSONObject) {
    accountCampaigns(account_id: $account_id, params: $params) {
      campaigns {
        _id
        title
        name
        description
        initial_date
        start_date
        final_date
        end_date
        scheduled
        mode
        created_at
        updated_at
        metrics {
          last_updated_at
          analytic_metrics
          clients_metrics
          general_metrics
          products_metrics_table
          products_metrics_totals
          recurrency_metrics
        }
        active
      }
      count
    }
  }
`
export const MUTATION_CHANGE_SCHEDULED_STATUS = gql`
  mutation($account_id: ID!, $campaign_id: ID!, $value: Boolean!) {
    changeCampaignScheduled(account_id: $account_id, campaign_id: $campaign_id, value: $value)
  }
`
export const QUERY_GET_CAMPAIGN = gql`
  query($account_id: ID!, $id: ID!) {
    accountCampaign(account_id: $account_id, id: $id) {
      _id
      title
      name
      description
      initial_date
      start_date
      final_date
      end_date
      scheduled
      mode
      created_at
      updated_at
      message {
        text
        send_date
        send_time
        sent
        type
        sent_at
      }
      metrics {
        last_updated_at
        analytic_metrics
        clients_metrics
        general_metrics
        products_metrics_table
        products_metrics_totals
        recurrency_metrics
      }
      active
    }
  }
`
export const MUTATION_DELETE_CAMPAIGN_METRIC = gql`
  mutation($account_id: ID!, $campaign_id: ID!) {
    deleteMetricCampaign(account_id: $account_id, campaign_id: $campaign_id)
  }
`
