import gql from 'graphql-tag'

export const MUTATION_HOTSITE_CONFIGURATION = gql`
  mutation UpdateHotsiteConfiguration($urls: [String]!, $configuration: AccountHotsiteConfigurationInput!) {
    updateHotsiteConfiguration(urls: $urls, configuration: $configuration)
  }
`

export const QUERY_ACCOUNT = gql`
  query {
    account {
      channels {
        hotsite {
          urls
          configuration {
            assets {
              positions {
                formImage
              }
              images {
                home {
                  desktop
                  tablet
                  smartphone
                }
                form {
                  desktop
                  tablet
                  smartphone
                }
                finish {
                  desktop
                  tablet
                  smartphone
                }
              }
              colors {
                home {
                  background
                  buttonBackground
                  buttonFont
                  input
                }
                form {
                  buttonBackground
                  buttonFont
                  toolbar
                }
                finish {
                  background
                  buttonBackground
                  buttonFont
                  text
                }
              }
            }
            fields {
              allowed_person_types_signup
              natural {
                name
                type
                width
                label
                options {
                  label
                  value
                }
                mask
                placeholder
                validators
                backend
                mandatory
              }
              legal {
                name
                type
                width
                label
                options {
                  label
                  value
                }
                mask
                placeholder
                validators
                backend
                mandatory
              }
            }
          }
        }
        ecommerce {
          messages {
            new {
              delivery
              pickup
              recipients
            }
            confirmed {
              delivery
              pickup
            }
            separation {
              delivery
              pickup
            }
            awaiting {
              delivery
              pickup
            }
            concluded {
              delivery
              pickup
            }
            canceled {
              delivery
              pickup
            }
          }
          build {
            url
            colors {
              primary
              font_primary
              secondary
              font_secondary
              tertiary
              font_tertiary
            }
            assets {
              logo
              favicon
            }
          }
        }
      }
    }
  }
`

export const QUERY_TERMS_CONFIGURATION = gql`
  query($account_id: ID!) {
    configuration(account_id: $account_id) {
      loyalty_terms {
        version
        content
      }
    }
  }
`

export const MUTATION_LOYALTY_TERMS = gql`
  mutation UpdateAccountLoyaltyTerms($account_id: ID!, $version: String!, $content: String!) {
    updateAccountLoyaltyTerms(account_id: $account_id, content: $content, version: $version)
  }
`

export const MUTATION_SINGLE_UPLOAD = gql`
  mutation($file: Upload!, $prefix: String) {
    singleUpload(file: $file, prefix: $prefix) {
      url
    }
  }
`

export const QUERY_URL_IS_AVAILABLE = gql`
  query($url: String!) {
    urlIsAvailable(url: $url)
  }
`
