import gql from 'graphql-tag'

export const QUERY_VOUCHERS = gql`
  query($account_id: ID!, $page: Int, $pageSize: Int, $search: String, $sort: [String], $sortDirection: [SortDirection]) {
    vouchers(account_id: $account_id, page: $page, pageSize: $pageSize, search: $search, sort: $sort, sortDirection: $sortDirection) {
      count
      data {
        _id
        document_no
        active_voucher {
          _id
          title
          description
          money
          points
          scheduled
          updated_at
          initial_date
          final_date
          created_at
          unit_id
          activated_at
        }
        voucher_history {
          _id
          money
          points
          unit_id
          activated_at
          returned_at
          locked_by
          consumed_at
        }
      }
    }
  }
`
export const QUERY_GET_VOUCHER = gql`
  query($account_id: ID!, $voucher_id: ID!) {
    voucher(account_id: $account_id, voucher_id: $voucher_id) {
      _id
      document_no
      active_voucher {
        _id
        title
        description
        money
        points
        scheduled
        updated_at
        initial_date
        final_date
        created_at
        unit_id
        activated_at
      }
      voucher_history {
        id: locked_by
        _id
        money
        points
        unit_id
        activated_at
        returned_at
        locked_by
        consumed_at
      }
    }
  }
`
