import gql from 'graphql-tag'

export const QUERY_GET_WPP_USERS = gql`
  query adminWppUsers($id: ID!, $page: Int, $pageSize: Int, $search: String, $sort: [String], $sortDirection: [SortDirection]) {
    adminWppUsers(account_id: $id, page: $page, pageSize: $pageSize, search: $search, sort: $sort, sortDirection: $sortDirection) {
      data {
        _id
        number
        register
        active
        created_at
        updated_at
        client_id
        loyalty
      }
      count
    }
  }
`
export const MUTATION_ACTIVE_WAPP_NUMBER = gql`
  mutation adminWappActive($id: ID!, $contact_id: String!, $active: Boolean!) {
    adminWappActive(id: $id, contact_id: $contact_id, active: $active)
  }
`
export const MUTATION_DELETE_WAPP_NUMBER = gql`
  mutation adminDeleteWapp($id: ID!, $contact_id: String!) {
    adminDeleteWapp(id: $id, contact_id: $contact_id)
  }
`
export const MUTATION_TRIGGER_WPP_ACTION = gql`
  mutation adminWppTriggerAction($account_id: ID!, $id_list: [ID!], $command: String) {
    adminWppTriggerAction(account_id: $account_id, id_list: $id_list, command: $command)
  }
`
export const MUTATION_DELETE_CONTACTS_FROM_CSV = gql`
  mutation adminDeleteFromCsv($id: ID!, $contacts: [String]) {
    adminDeleteFromCsv(id: $id, contacts: $contacts)
  }
`
export const MUTATION_INSERT_CONTACTS_FROM_CSV = gql`
  mutation adminInsertFromCsv($id: ID!, $contacts: [JSONObject]) {
    adminInsertFromCsv(id: $id, contacts: $contacts)
  }
`

export const GET_INSTANCES_WHATSAPP = gql`
  query getWhatsAppInstances($input: GetInstancesInput!, $pagination: PaginationParams!) {
    getWhatsAppInstances(input: $input, pagination: $pagination) {
      _id
      account_id
      active
      address
      deploy_tag
      emulator
      last_bootup
      multi_device
      qrcode
      qrcode_request_id
      qrcode_scanned
      queue_to_send
      vnc_password
      number
    }
  }
`
export const MUTATION_DELETE_INSTANCE = gql`
  mutation removeWhatsAppInstance($instance_id: String!) {
    removeWhatsAppInstance(input: { instance_id: $instance_id }) {
      message
      success
      url
    }
  }
`

export const MUTATION_CREATE_INSTANCE = gql`
  mutation addWhatsAppInstance($input: AddInstanceInput!) {
    addWhatsAppInstance(input: $input) {
      message
      success
      url
    }
  }
`
