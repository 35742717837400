import gql from 'graphql-tag'

export const QUERY_GET_OPERATION_LOGS = gql`
  query adminLogs($id: ID!) {
    adminLogs(id: $id) {
      _id
      user {
        login
      }
      user_id
      error
      collection
      created_at
      delta
      log_message
    }
  }
`
